import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { PostSurveyService } from '../../../service/post-survey/post-survey.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Base64 } from 'js-base64';
import { GetJsonService } from '../../../service/get-json/get-json.service';
import { SurveyResultService } from '../../../service/survey-result/survey-result.service';
import { anyRegExp, numberRegExp } from './dialog.data';
@Component({
  selector: 'app-post-survey-detail',
  templateUrl: './post-survey-detail.component.html',
  styleUrls: ['./post-survey-detail.component.scss']
})

export class PostSurveyDetailComponent implements OnInit {
  translationData: any;
  isLayout: boolean = false;
  surveyResultData: any[] = [];
  languageNum: number = 0;
  isRequest: boolean = false;
  detailData: any;
  languageType: number = 0;

  constructor(
    private store: Store<any>,
    private router: Router,
    private route: ActivatedRoute,
    private message: NzMessageService,
    private postSurveyService: PostSurveyService,
    private getJsonService: GetJsonService,
    private surveyResultService: SurveyResultService
  ) {}

  ngOnInit() {
    this.store.subscribe(data => {
      if(Object.keys(data).length > 0 && data.data){
        const { type, payload } = data.data;
        switch(type){
          case 'languageType':
            this.languageNum = Number(payload);
            this.changeLanguageEvent(this.languageNum);
            break;
          case 'progress':
            this.isRequest = (payload === 'request-loading' ? true : false);
            break;
        }
      }
    });
    this.route.params.subscribe(res=>{
      const { _value:{ data: params} } = JSON.parse(JSON.stringify(this.route.queryParams));
      if(params){
        const detailData: any = JSON.parse(Base64.decode(params));
        const {
          parentData,
        } = detailData;
        this.detailData = detailData;
        this.getAuditSurveyResultData(parentData.queVer + '/' + parentData.id);
      }else{
        this.router.navigate(['main/postSurvey/empty']);
      }
    });
  }

  // change event
  changeLanguageEvent(languageNum: number): void{
    this.languageType = languageNum;
    switch(languageNum){
      case 0: // en
        this.getJsonService.getJsonDataService('./assets/i18n/en.json').subscribe(res=>{
          this.translationData = this.recordEvent(this.translationData?.detailModel,res);
          this.isLayout = true;
        });
        break;
      case 1: // tc
        this.getJsonService.getJsonDataService('./assets/i18n/tc.json').subscribe(res=>{
          this.translationData = this.recordEvent(this.translationData?.detailModel,res);
          this.isLayout = true;
        });
        break;
      case 2: // sc
        this.getJsonService.getJsonDataService('./assets/i18n/sc.json').subscribe(res=>{
          this.translationData = this.recordEvent(this.translationData?.detailModel,res);
          this.isLayout = true;
        });
        break;
    }
  }

  // record event
  recordEvent(oldData: any,newData: any): any{
    const { detailModel:{companyLabel} } = newData;
    if(oldData){
      // company
      newData.detailModel.auditListData = this.companyChangeLabelEvent(oldData.auditListData,companyLabel);
      // questionnaire
      newData.detailModel.questionnaire = this.surveyResultData;
      newData.detailModel.questionnaire.map((item: any,itemIdx: number)=>{
        item.question.map((child: any,childIdx: number)=>{
          const itemValue: any = oldData.questionnaire[itemIdx];
          child.value = itemValue.question[childIdx].value;
          return child;
        });
        return item;
      });
      this.dataChange();
    }else{
      // company
      newData.detailModel.auditListData = this.companyChangeLabelEvent(this.detailData.company,companyLabel);
    }
    return newData;
  }

  dataChange(): void{
    const { detailModel:{auditListData}} = this.translationData;
    auditListData.map((item: any)=>{
      item.company.map((child: any,childIdx: number)=>{
        switch(childIdx){
          case 3: // company name
            switch(this.languageType){
              case 0: // en
                child.value = child.companyNameL1;
                break;
              case 1: // tc
                child.value = child.companyNameL2;
                break;
              case 2: // sc
                child.value = child.companyNameL3;
                break;
            }
            break;
        }
      });
    });
  }

  // get audit survey result data
  getAuditSurveyResultData(url: string): void{
    const currentLanguage: any = sessionStorage.getItem('languageNumber');
    if(currentLanguage){
      this.languageNum = Number(currentLanguage);
    }
    this.changeLanguageEvent(this.languageNum);
    this.postSurveyService.GetAuditSurveyDataService(url).toPromise().then(res=>{
      const { code,data,result } = res;
      switch(code){
        case '200_10':
          if(data.surveySectionModels){
            data.surveySectionModels.forEach((item: any)=>{
              const questionData: any[] = [];
              item.surveyMasterModels.forEach((child: any)=>{
                switch(child.ansType){
                  case 'n': // radio type
                    questionData.push({
                      queDescL1: child.queNo + '. ' + child.queDescL1,
                      queDescL2: child.queNo + '. ' + child.queDescL2,
                      queDescL3: child.queNo + '. ' + child.queDescL3,
                      queNo: child.queNo,
                      value: '',
                      type: 0
                    });
                    break;
                  case 'b': // checkbox type
                    questionData.push({
                      queDescL1: child.queNo + '. ' + child.queDescL1,
                      queDescL2: child.queNo + '. ' + child.queDescL2,
                      queDescL3: child.queNo + '. ' + child.queDescL3,
                      queNo: child.queNo,
                      value: '',
                      yesL1: 'Yes',
                      yesL2: '會',
                      yesL3: '会',
                      noL1: 'No',
                      noL2: '否',
                      noL3: '否',
                      type: 1
                    });
                    break;
                  case 'v': // textarea type
                    questionData.push({
                      queDescL1: child.queNo + '. ' + child.queDescL1,
                      queDescL2: child.queNo + '. ' + child.queDescL2,
                      queDescL3: child.queNo + '. ' + child.queDescL3,
                      queNo: child.queNo,
                      value: '',
                      type: 2
                    });
                    break;
                }
              });
              this.surveyResultData.push({
                subheadingL1: item.sectionDescEng,
                subheadingL2: item.sectionDescTchi,
                subheadingL3: item.sectionDescSchi,
                asrdAnswerNumber: item.queVer,
                question: questionData
              });
            });
            this.changeLanguageEvent(this.languageNum);
          }
          break;
        case '300_31': // already submit data
          this.message.create('success',result,{nzDuration: 3000});
          this.router.navigate(['success']);
          break;
      }
    }).catch((error: any)=>{
      switch(error.status){
        case 401:
          this.message.create('error',error.error.Result);
          window.history.back();
          break;
      }
    });
  }

  // company part change label
  companyChangeLabelEvent(companyData: any[],labelData: any[]): any{
    if(companyData){
      companyData.map((item: any)=>{
        item.company.map((child: any,childIdx: number)=>{
          child.label = labelData[childIdx].label;
          if(child.isInput){
            child.error = this.languageChangeEvent(Object.assign({
              value: child.value
            },labelData[childIdx]));
          }
          return child;
        });
        return item;
      });
      return companyData;
    }else{
      this.router.navigate(['empty']);
    }
  }

  // input event
  inputEvent(params: any,idx: number): any{
    switch(idx){
      case 4: // tel no
        if(numberRegExp.test(params.value)){
          params.isRequire = false;
        }else{
          if(params.value.indexOf('+') !== -1 || params.value.indexOf('-') !== -1 || params.value.indexOf(' ') !== -1){
            if(params.value.length > 7 && params.value.length < 21){
              params.isRequire = false;
            }else{
              switch(this.languageNum){
                case 0:
                  params.error = params.validL1;
                  break;
                case 1:
                  params.error = params.validL2;
                  break;
                case 2:
                  params.error = params.validL3;
                  break;
              }
              params.isRequire = true;
            }
          }else{
            if(params.value.length > 0){
              switch(this.languageNum){
                case 0:
                  params.error = params.validL1;
                  break;
                case 1:
                  params.error = params.validL2;
                  break;
                case 2:
                  params.error = params.validL3;
                  break;
              }
            }else{
              switch(this.languageNum){
                case 0:
                  params.error = params.emptyL1;
                  break;
                case 1:
                  params.error = params.emptyL2;
                  break;
                case 2:
                  params.error = params.emptyL3;
                  break;
              }
            }
            params.isRequire = true;
          }
        }
        break;
      case 5: // filled by
          switch(anyRegExp.test(params.value)){
            case true:
              params.isRequire = false;
              break;
            case false:
              if(params.value.length > 0){
                switch(this.languageNum){
                  case 0:
                    params.error = params.validL1;
                    break;
                  case 1:
                    params.error = params.validL2;
                    break;
                  case 2:
                    params.error = params.validL3;
                    break;
                }
              }else{
                switch(this.languageNum){
                  case 0:
                    params.error = params.emptyL1;
                    break;
                  case 1:
                    params.error = params.emptyL2;
                    break;
                  case 2:
                    params.error = params.emptyL3;
                    break;
                }
              }
              params.isRequire = true;
              break;
          }
        break;
    }
    return params;
  }

  languageChangeEvent(params: any): string{
    let currentLabel: string = '';
    switch(this.languageNum){
      case 0:
        switch(params.value){
          case '':
            currentLabel = params.emptyL1;
            break;
          default:
            currentLabel = params.validL1;
            break;
        }
        break;
      case 1:
        switch(params.value){
          case '':
            currentLabel = params.emptyL2;
            break;
          default:
            currentLabel = params.validL2;
            break;
        }
        break;
      case 2:
        switch(params.value){
          case '':
            currentLabel = params.emptyL3;
            break;
          default:
            currentLabel = params.validL3;
            break;
        }
        break;
    }
    return currentLabel;
  }

  // btn event
  btnEvent(): void{
    const { detailModel: {auditListData} } = this.translationData;
    let passNumber: number = 0;
    let noPassNumber: number = 0;
    auditListData.map((item: any)=>{
      item.company.map((child: any,childIdx: number)=>{
        if(child.isInput){
          noPassNumber++;
          switch(childIdx){
            case 4: // tel no
              if(numberRegExp.test(child.value)){
                child.isRequire = false;
                passNumber++;
              }else{
                if(child.value.indexOf('+') !== -1 || child.value.indexOf('-') !== -1 || child.value.indexOf(' ') !== -1){
                  if(child.value.length > 7 && child.value.length < 21){
                    child.isRequire = false;
                    passNumber++;
                  }else{
                    switch(this.languageNum){
                      case 0:
                        child.error = child.validL1;
                        break;
                      case 1:
                        child.error = child.validL2;
                        break;
                      case 2:
                        child.error = child.validL3;
                        break;
                    }
                    child.isRequire = true;
                  }
                }else{
                  if(child.value.length > 0){
                    switch(this.languageNum){
                      case 0:
                        child.error = child.validL1;
                        break;
                      case 1:
                        child.error = child.validL2;
                        break;
                      case 2:
                        child.error = child.validL3;
                        break;
                    }
                  }else{
                    switch(this.languageNum){
                      case 0:
                        child.error = child.emptyL1;
                        break;
                      case 1:
                        child.error = child.emptyL2;
                        break;
                      case 2:
                        child.error = child.emptyL3;
                        break;
                    }
                  }
                  child.isRequire = true;
                }
              }
              break;
            case 5: // filled by
              switch(anyRegExp.test(child.value)){
                case true:
                  child.isRequire = false;
                  passNumber++;
                  break;
                case false:
                  if(child.value.length > 0){
                    switch(this.languageNum){
                      case 0:
                        child.error = child.validL1;
                        break;
                      case 1:
                        child.error = child.validL2;
                        break;
                      case 2:
                        child.error = child.validL3;
                        break;
                    }
                  }else{
                    switch(this.languageNum){
                      case 0:
                        child.error = child.emptyL1;
                        break;
                      case 1:
                        child.error = child.emptyL2;
                        break;
                      case 2:
                        child.error = child.emptyL3;
                        break;
                    }
                  }
                  child.isRequire = true;
                  break;
              }
              break;
          }
        }
        return child;
      })
      return item;
    });
    if(passNumber === noPassNumber){
      const submitAuditNo: string[] = [];
      let submitTelNo: string = '';
      let submitFilledInBy: string = '';
      this.detailData.company.forEach((item: any,idx: number)=>{
        if(idx === 0){
          item.company.forEach((child: any,childIdx: number)=>{
            switch(childIdx){
              case 0: // audit no
                submitAuditNo.push(child.value);
                break;
              case 4: // tel no
                submitTelNo = child.value;
                break;
              case 5: // fill by
                submitFilledInBy = child.value;
                break;
            }
          })
        }
      });
      const { detailModel:{questionnaire} } = this.translationData;
      const questionData: any[] = [];
      questionnaire.forEach((item: any,idx: number)=>{
        item.question.forEach((child: any)=>{
          switch(child.type){
            case 0: // radio type
              questionData.push({
                asrdAsqdId: child.queNo,
                asrdAnswerNumber: child.value ? Number(child.value) : null
              });
              break;
            case 1: // checkbox type
              questionData.push({
                asrdAsqdId: child.queNo,
                asrdAnswerBoolean: (child.value ? (child.value === '1' ? 'Y' : 'N') : null)
              });
              break;
            case 2: // textarea type
              questionData.push({
                asrdAsqdId: child.queNo,
                asrdAnswerVarchar: child.value ? child.value : null
              });
              break;
          }
        });
      });
      const submitObj: any = {
        id: this.detailData.parentData.queVer,
        queVer: this.detailData.parentData.id,
        auditNos: [...new Set(submitAuditNo.concat(this.detailData.checkedAuditNo))],
        inputAuditNo: this.detailData.inputAuditNo.join(','),
        telNo: submitTelNo,
        filledInBy: submitFilledInBy,
        addAsrdAuditSurveyResultDtlRequestModels: questionData
      };
      this.addService(submitObj);
    }else{
      const currentHtml: any = document.getElementById('contentId');
      currentHtml.scrollTo(0,0);
    }
  }

  addService(params: any): void{
    this.surveyResultService.PostSurveyResultDataService(params).subscribe(res=>{
      const { code,result } = res;
      switch(code){
        case '200_10':
          this.message.create('success',result,{nzDuration: 3000});
          this.router.navigate(['success']);
          break;
      }
    });
  }
}