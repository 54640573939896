import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  nonce: string | null = null;
  title = 'Post-Audit-Survey-Frontend';

  constructor(private renderer: Renderer2, private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.addDynamicElements();
      }
    });
  }

  ngOnInit() {
    const nonceMetaTag = document.querySelector('meta[name="csp-nonce"]');
    if (nonceMetaTag) {
      this.nonce = nonceMetaTag.getAttribute('content');
      // console.log('Nonce fetched:', this.nonce);
    } else {
      console.error('Nonce meta tag not found.');
    }

    if (this.nonce) {
      this.addDynamicElements();

      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.type === 'childList') {
            mutation.addedNodes.forEach(node => {
              if (node.nodeType === Node.ELEMENT_NODE) {
                if ((node as Element).tagName === 'STYLE') {
                  (node as HTMLElement).setAttribute('nonce', this.nonce!);
                  // console.log('Nonce set for new style:', node);
                }
                if ((node as Element).tagName === 'SCRIPT') {
                  (node as HTMLElement).setAttribute('nonce', this.nonce!);
                  // console.log('Nonce set for new script:', node);
                }
              }
            });
          }
        });
      });

      observer.observe(document.head, { childList: true, subtree: true });
    }
  }

  private addStyle() {
    const style = this.renderer.createElement('style');
    this.renderer.setAttribute(style, 'nonce', this.nonce!);
    // this.renderer.appendChild(style, this.renderer.createText('body { background-color: #f3f3f3; }'));//测试添加样式
    this.renderer.appendChild(document.head, style);
    // console.log('Dynamically added style with nonce:', style);
  }

  private addScript() {
    const script = document.createElement('script');
    script.setAttribute('nonce', this.nonce!);
    // script.textContent = `
    //   console.log('Script with nonce executed');
    // `;
    document.body.appendChild(script);
    // console.log('Dynamically added script with nonce:', script);
  }

  private addDynamicElements() {
    this.addStyle();
    this.addScript();
  }
}
