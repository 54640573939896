import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-post-complete',
  templateUrl: './post-complete.component.html',
  styleUrls: ['./post-complete.component.scss']
})

export class PostCompleteComponent implements OnInit {

  constructor() { }

  ngOnInit() {}
}