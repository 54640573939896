import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { GetJsonService } from '../../service/get-json/get-json.service';
import { LanguageData } from '../../components/language-dialog/dialog.data';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  isSuccess: boolean = false;
  promptInfo: string = '';
  languageDialog: any = {
    isVisible: false,
    languageData: LanguageData,
    activeLanguage: 0
  };

  constructor(
    private store: Store<any>,
    private getJsonService: GetJsonService
  ) { }

  ngOnInit() {
    const languageNumber = sessionStorage.getItem('languageNumber');
    if(languageNumber){
      this.store.dispatch({
        type: 'languageType',
        payload: Number(languageNumber)
      });
      this.changeLanguageEvent(Number(languageNumber));
    }else{
      this.languageDialog.isVisible = true;
      this.store.dispatch({
        type: 'languageType',
        payload: 99
      });
    }
    if(window.location.href.indexOf('success') !== -1){
      this.isSuccess = true;
    }else{
      this.isSuccess = false;
    }
  }

  // radio event call back event
  radioCallBackEvent(params: any): void{
    this.store.dispatch({
      type: 'languageType',
      payload: params.activeLanguage
    });
    sessionStorage.setItem('languageNumber',String(params.activeLanguage));
    this.changeLanguageEvent(params.activeLanguage);
  }

  // change event
  changeLanguageEvent(languageNum: number): void{
    switch(languageNum){
      case 0: // en
        this.getJsonService.getJsonDataService('./assets/i18n/en.json').subscribe(res=>{
          this.promptInfo = res.promptInfo;
        });
        break;
      case 1: // tc
        this.getJsonService.getJsonDataService('./assets/i18n/tc.json').subscribe(res=>{
          this.promptInfo = res.promptInfo;
        });
        break;
      case 2: // sc
        this.getJsonService.getJsonDataService('./assets/i18n/sc.json').subscribe(res=>{
          this.promptInfo = res.promptInfo;
        });
        break;
    }
  }
}