import { API_ENV, API_SURVEY_MODULE_NAME } from "../../config";
import { PostAuditApi } from "./post-audit";

let PostAuditSurveySummaryAPI: any = {};
const PostAuditSummaryModule: any[] = [
    PostAuditApi
];

function getCurrentAddress(data: any,rootPath: string): any{
    let currentAddress: any = {};
    for(let item in data){
        currentAddress[item] = rootPath + data[item];
    }
    return currentAddress;
};

PostAuditSummaryModule.forEach((item: any,idx: number)=>{
    for(let child in item){
        PostAuditSurveySummaryAPI = Object.assign({
            ['PostAuditModuleName' + (idx+1)] : getCurrentAddress(item,API_ENV + API_SURVEY_MODULE_NAME)
        });
    }
});

export {
    PostAuditSurveySummaryAPI,
    getCurrentAddress
}