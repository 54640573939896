import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from '../views/layout/layout.component';
import { PostSurveyListComponent } from '../views/post-survey/post-survey-list/post-survey-list.component';
import { PostSurveyDetailComponent } from '../views/post-survey/post-survey-detail/post-survey-detail.component';
import { PostCompleteComponent } from '../views/post-survey/post-complete/post-complete.component';
import { EmptyComponent } from '../views/post-survey/empty/empty.component';

const route: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: [
            {
                path: 'list/:id/:name',
                component: PostSurveyListComponent
            },
            {
                path: 'list/:data',
                component: PostSurveyDetailComponent
            },
            {
                path: 'success',
                component: PostCompleteComponent
            },
            {
                path: 'empty',
                component: EmptyComponent
            },
            {
                path: '**',
                redirectTo: '/empty',
                pathMatch: 'prefix'
            }
        ],
    },
    {
        path: '**',
        redirectTo: '/empty',
        pathMatch: 'prefix'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(route)
    ],
    exports:[RouterModule]
})

export class AppRouter { }