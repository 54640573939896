import * as moment from "moment";
import { FormatMonthLabel } from "../../config";

function formatDataDataFn(data: any,args: any): any{
    const customDate: any = moment(new Date(data)).format(args);
    return customDate;
}

function translationDateDataFn(params: any): string{
    let strData: string[] = [];
    let splitData: string[] = params.split('-');
    (splitData.reverse()).forEach((item: string,itemIdx: number)=>{
        switch(itemIdx){
            case 1:
                strData.push(FormatMonthLabel[item]);
                break;
            default:
                strData.push(item);
                break;
        }
    });
    return strData.join('-');
}

export {
    formatDataDataFn,
    translationDateDataFn
}