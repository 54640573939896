<div class="post-survey-list" [hidden]="!isLayout">
    <div class="title-box">
        <div>
            <p>{{translationData?.listModel?.title1}}</p>
            <p>{{translationData?.listModel?.title2}}</p>
            <p>{{translationData?.listModel?.submitTitle}}</p>
        </div>
    </div>
    <div class="company-box">
        <div class="company-header-box">
            <div class="item-box">
                <span>{{translationData?.listModel?.companyName}} : </span>
                <p>{{this.initResultData?.companyName}}</p>
            </div>
            <p>{{translationData?.listModel?.selectIdentification}}</p>
        </div>
        <!-- pc-layout -->
        <table>
            <thead>
                <tr>
                    <th>
                        <span
                            nz-checkbox
                            [nzDisabled]="allDisabled"
                            [nzChecked]="checked"
                            [nzIndeterminate]="indeterminate"
                            (nzCheckedChange)="onAllChecked($event)">
                        </span>
                    </th>
                    <th
                        *ngFor="let item of translationData?.listModel?.tableThTile">
                        {{item}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let item of auditListData;"
                    [hidden]="auditListData.length === 0">
                    <td>
                        <span
                            nz-checkbox
                            [nzChecked]="setOfCheckedId.has(item.atsAuditNo)"
                            (nzCheckedChange)="onItemChecked(item.atsAuditNo,$event)">
                        </span>
                    </td>
                    <td>{{item.atsAuditNo ? item.atsAuditNo : '--'}}</td>
                    <td>
                        <span [style]="(item.ccNo === null && item.transactionNo === null) ? 'display: block' : 'display: none'">--</span>
                        <span [style]="(item.ccNo !== null || item.transactionNo !== null) ? 'display: block' : 'display: none'">
                            <em [hidden]="item.ccNo === null">{{item.ccNo}} / </em>
                            <em [hidden]="item.transactionNo === null">{{item.transactionNo}}</em>
                        </span>
                    </td>
                    <td>{{item.auditEndDate | formatDate}}</td>
                    <td>
                        <div
                            *ngIf="item.auditTeamInfos">
                            <span
                                *ngFor="let child of item.auditTeamInfos"
                                class="team-box">
                                <span>{{child.auditTeamName}}</span>
                            </span>
                        </div>
                        <span *ngIf="!item.auditTeamInfos || item.auditTeamInfos.length === 0">--</span>
                    </td>
                    <td>{{item.standard ? item.standard : '--'}}</td>
                    <td>{{item.auditType ? item.auditType : '--'}}</td>
                </tr>
                <tr class="no-data" [hidden]="auditListData.length !== 0">
                    <td colspan="7">
                        <p [hidden]="isRequest">No data</p>
                        <p [hidden]="!isRequest">
                            Loading
                            <span nz-icon nzType="loading" nzTheme="outline"></span>
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
        <!-- mobile-layout -->
        <div class="mobile-box">
            <span
                nz-checkbox
                [nzChecked]="checked"
                [nzDisabled]="allDisabled"
                [nzIndeterminate]="indeterminate"
                (nzCheckedChange)="onAllChecked($event)"
                class="check-all"
                [hidden]="auditListData.length === 0">
            </span>
            <div
                *ngFor="let item of auditListData;"
                class="mobile-item-box">
                <span
                    nz-checkbox
                    [nzChecked]="setOfCheckedId.has(item.atsAuditNo)"
                    (nzCheckedChange)="onItemChecked(item.atsAuditNo,$event)">
                </span>
                <div
                    *ngFor="let label of translationData?.listModel?.tableThTile;
                    let childIdx = index;"
                    [hidden]="auditListData.length === 0">
                    <span>{{label}} : </span>
                    <p *ngIf="childIdx === 0 ? true : false">{{item.atsAuditNo}}</p>
                    <p *ngIf="childIdx === 1 ? true : false">
                        <span [style]="(item.ccNo === null && item.transactionNo === null) ? 'display: block' : 'display: none'">--</span>
                        <span [style]="(item.ccNo !== null || item.transactionNo !== null) ? 'display: block' : 'display: none'">
                            <em [hidden]="item.ccNo === null">{{item.ccNo}} / </em>
                            <em [hidden]="item.transactionNo === null">{{item.transactionNo}}</em>
                        </span>
                    </p>
                    <p *ngIf="childIdx === 2 ? true : false">{{item.auditEndDate | formatDate}}</p>
                    <div *ngIf="childIdx === 3 ? true : false">
                        <div
                            *ngIf="item.auditTeamInfos">
                            <span
                                *ngFor="let child of item.auditTeamInfos"
                                class="team-box">
                                {{child.auditTeamName}}
                            </span>
                        </div>
                        <span *ngIf="!item.auditTeamInfos || item.auditTeamInfos.length === 0">--</span>
                    </div>
                    <p *ngIf="childIdx === 4 ? true : false">{{item.standard ? item.standard : '--'}}</p>
                    <p *ngIf="childIdx === 5 ? true : false">{{item.auditType ? item.auditType : '--'}}</p>
                </div>
            </div>
            <div class="no-data" [hidden]="auditListData.length !== 0">
                <p [hidden]="isRequest">No data</p>
                <p [hidden]="!isRequest">
                    Loading
                    <span nz-icon nzType="loading" nzTheme="outline"></span>
                </p>
            </div>
        </div>
        <div [class]="remarkRequire ? 'remark-box require-remark-box' : 'remark-box'">
            <p [innerHTML]="translationData?.listModel?.remark"></p>
            <div class="input-box">
                <div
                    *ngFor="let item of translationData?.listModel?.additionalAuditNo;
                    let index = index;"
                    [class]="item.isRequire ? 'error-input' : ''">
                    <input
                        placeholder="Audit No."
                        [(ngModel)]="item.value"
                        (ngModelChange)="inputEvent(item,index)"
                        nz-input/>
                    <!-- <span [hidden]="!item.isRequire" class="error">{{item.error}}</span> -->
                </div>
            </div>
        </div>
        <div class="btn-box">
            <button
                (click)="btnEvent()"
                nzType="primary"
                nz-button
                [disabled]="this.setOfCheckedId.size === 0 ? true : false">
                {{translationData?.listModel?.nextButton}}
            </button>
        </div>
    </div>
</div>