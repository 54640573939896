import { Pipe, PipeTransform } from '@angular/core';
import { formatDataDataFn, translationDateDataFn } from '.';
import { FormatMonthLabel } from '../../config';

@Pipe({
  name: 'formatDate'
})

export class FormatDatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if(value){
      if(args){
        return formatDataDataFn(value,args);
      }else{
        return translationDateDataFn(formatDataDataFn(value,'YYYY-MM-DD'));
      }
    }else{
      return '--';
    }
  }
}