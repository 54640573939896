import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRouter } from './router';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { CommonModule, registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { ApplicationStatus } from './core';
import { PostSurveyDetailComponent } from './views/post-survey/post-survey-detail/post-survey-detail.component';
import { PostCompleteComponent } from './views/post-survey/post-complete/post-complete.component';
import { EmptyComponent } from './views/post-survey/empty/empty.component';
import { LayoutComponent } from './views/layout/layout.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzResultModule } from 'ng-zorro-antd/result';
import { CommonPipeModule } from './pipe/pipe.module';
import { InterceptorService } from './service/interceptor/interceptor.service';
import { PostSurveyListComponent } from './views/post-survey/post-survey-list/post-survey-list.component';
import { NzModalModule, NzModalService } from 'ng-zorro-antd/modal';
import { ComponentsModule } from './components/components.module';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NonceService } from './service/nonce/nonce.service';

registerLocaleData(en);

@NgModule({
    declarations: [
        AppComponent,
        LayoutComponent,
        PostSurveyListComponent,
        PostSurveyDetailComponent,
        PostCompleteComponent,
        EmptyComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        AppRouter,
        CommonModule,
        ReactiveFormsModule,
        NzInputModule,
        NzIconModule,
        NzDropDownModule,
        NzMessageModule,
        NzLayoutModule,
        NzButtonModule,
        NzCheckboxModule,
        NzRadioModule,
        NzResultModule,
        NzModalModule,
        CommonPipeModule,
        ComponentsModule,
        StoreModule.forRoot({ data: ApplicationStatus })
    ],
    providers: [
        {
            provide: NZ_I18N,
            useValue: en_US
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true
        },
        NonceService,
        NzModalService,
        provideHttpClient(withInterceptorsFromDi())
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
