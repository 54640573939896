import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConf } from '../../api';
import { Observable } from 'rxjs';
import { ResponseModel } from '../../model';

@Injectable({
  providedIn: 'root'
})

export class SurveyResultService {
  API_ENV: string = '';

  constructor(
    private http: HttpClient,
    private appConf: AppConf
  ) {
    const { PostAuditSurveySummaryAPI:{
      PostAuditModuleName1:{
        AsrhAuditSurveyResultHdr
      }
    }} = this.appConf;
    this.API_ENV = AsrhAuditSurveyResultHdr;
  }

  // add survey result data
  public PostSurveyResultDataService(params: any): Observable<ResponseModel<any>>{
    return this.http.post<ResponseModel<any>>(
      this.API_ENV,params)
  }
}