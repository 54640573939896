<nz-modal
    [(nzVisible)]="dialogData.isVisible"
    nzTitle="Select Language ( 選擇語言 )"
    nzMaskClosable="false"
    nzClassName="languageDialog-box"
    [nzContent]="nzModalContent"
    [nzFooter]="modalFooter"
    (nzOnCancel)="dialogEvent()">
    <ng-template #nzModalContent>
        <nz-radio-group
            [(ngModel)]="dialogData.activeLanguage">
            <label
                *ngFor="let item of dialogData.languageData;"
                nz-radio
                [nzValue]="item.value">
                {{item.label}}
            </label>
        </nz-radio-group>
    </ng-template>
    <ng-template #modalFooter>
        <div class="footer-box">
            <p class="button" (click)="dialogEvent()">Next ( 下一頁 )</p>
        </div>
    </ng-template>
</nz-modal>