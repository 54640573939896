import { Injectable } from "@angular/core";
import { API_ENV_LOGIN } from "../config";
import { PostAuditSurveySummaryAPI } from "./post-audit-survey";

@Injectable({
    providedIn: 'root'
})

export class AppConf {
    // login
    LoginApi = API_ENV_LOGIN + '/api-auth/connect/token';

    // post audit survey
    PostAuditSurveySummaryAPI = PostAuditSurveySummaryAPI;
}