import { environment } from "../../environments/environment";

// current api environment
const API_ENV: string = environment.API_ENV;
const API_ENV_LOGIN: string = environment.API_ENV_LOGIN;
// current api module
const API_SURVEY_MODULE_NAME: string = environment.isProductionUrl ? '/api-user-survey/api/' : '/api-user-survey/api/';

const TokenParams: any = {
    grant_type: 'client_credentials',
    client_id: '813730c0-b275-11e9-bced-0242ac1d010a',
    client_secret: 'M57auwThI9afC4YbqbWvi9WDD8b6BAIzmPnz0aHZy9oinnEgTRn6k51onokLfzU6'
};

const FormatMonthLabel: any = {
    '01' : 'Jan',
    '02' : 'Feb',
    '03' : 'Mar',
    '04' : 'Apr',
    '05' : 'May',
    '06' : 'Jun',
    '07' : 'Jul',
    '08' : 'Aug',
    '09' : 'Sep',
    '10' : 'Oct',
    '11' : 'Nov',
    '12' : 'Dec'
};

export {
    API_ENV,
    API_ENV_LOGIN,
    API_SURVEY_MODULE_NAME,
    TokenParams,
    FormatMonthLabel
}