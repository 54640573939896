import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-language-dialog',
  templateUrl: './language-dialog.component.html',
  styleUrls: ['./language-dialog.component.scss']
})

export class LanguageDialogComponent implements OnInit {
  @Input() dialogData: any;
  @Output() childEvent = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  // dialog event
  dialogEvent(): void{
    this.dialogData.isVisible = false;
    this.childEvent.emit(this.dialogData);
  }
}