import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { NzMessageService } from "ng-zorro-antd/message";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { catchError, filter, finalize, mergeMap, switchMap, take, tap } from "rxjs/operators";
import { GetTokenService } from "../get-token/get-token.service";
import { TokenParams } from "../../config";
import { NzModalService } from 'ng-zorro-antd/modal';

@Injectable({
  providedIn: 'root'
})

export class InterceptorService implements HttpInterceptor {
  refreshTokenInProgress = false;
  refreshTokenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public message: NzMessageService,
    public modal: NzModalService,
    private store: Store<any>,
    private getTokenService: GetTokenService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let url = req.url;
    this.storeRequestStatusEvent(true,url);
    // get the token of user
    let AuthorizationData: any = sessionStorage.getItem('Authorization');
    if(AuthorizationData){
      const new_token = JSON.parse(AuthorizationData).token_type + ' ' + JSON.parse(AuthorizationData).access_token;
      // request header parameter setting
      const params = req.clone({
        headers: req.headers
          .set("Content-Type", "application/json")
          .set("Access-Control-Allow-Origin", "*")
          .set("Cache-Control", "no-cache")
          .set("observe", "body")
          .set("Authorization", new_token),
      });
      return next.handle(params).pipe(
        mergeMap((event: HttpEvent<any>)=>{
          if (event instanceof HttpResponse) {
            const { body: { code, result }} = event;
            if(code === '200_10' || code === '300_31'){
              this.storeRequestStatusEvent(false,url);
            }else{
              if(code){
                this.message.create('error',result,{nzDuration: 3000});
              }
              this.storeRequestStatusEvent(false,url);
            }
          }
          return of(event);
        }),catchError((error: HttpErrorResponse) => {
          const { status, message } = error;
          this.storeRequestStatusEvent(true,url);
          if(String(status) === '401'){ // token expired
            if(this.refreshTokenInProgress){
              return this.refreshTokenSubject.pipe(
                filter((token: any)=>{
                  // reset the token
                  sessionStorage.setItem('Authorization',JSON.stringify(token));
                  return token;
                }),
                take(1),
                switchMap(() => next.handle(this.getTokenService.KeepOnRequestService(req)).pipe(tap((res)=>{
                  if (res instanceof HttpResponse) {
                    const { body: { code, result}} = res;
                    if(code !== '200_10'){
                      this.message.create('error',result,{nzDuration: 3000});
                    }
                  }
                }))),
                catchError(invalid_grant=>{
                  const {error: { error,Result }} = invalid_grant;
                  if(error){
                    sessionStorage.removeItem('Authorization');
                  }else{
                    sessionStorage.clear();
                  }
                  this.modal.info({
                    nzClassName:'custom-confirm-dialog',
                    nzTitle: String(error ? error : Result) + ' and the browser will refresh, continue ?',
                    nzOkType: 'primary',
                    nzOkText: 'Confirm',
                    nzOnOk: () => {
                      window.location.reload();
                    }
                  });
                  return throwError(invalid_grant);
                })
              )
            }else{
              this.refreshTokenInProgress = true;
              return this.getTokenService.PostTokenDataService(TokenParams).pipe(
                switchMap((token: any)=>{
                  // reset the token
                  this.refreshTokenSubject.next(token);
                  sessionStorage.setItem('Authorization',JSON.stringify(token));
                  return next.handle(this.getTokenService.KeepOnRequestService(req)).pipe(tap((res)=>{
                    if (res instanceof HttpResponse) {
                      const { body: { code, result}} = res;
                      if(code !== '200_10'){
                        this.message.create('error',result,{nzDuration: 3000});
                      }
                    }
                  }));
                }),
                finalize(()=>{this.refreshTokenInProgress = false}),
                catchError(invalid_grant=>{
                  const {error: { error,Result }} = invalid_grant;
                  if(error){
                    sessionStorage.removeItem('Authorization');
                  }else{
                    sessionStorage.clear();
                  }
                  this.modal.info({
                    nzClassName:'custom-confirm-dialog',
                    nzTitle: String(error ? error : Result) + ' and the browser will refresh, continue ?',
                    nzOkType: 'primary',
                    nzOkText: 'Confirm',
                    nzOnOk: () => {
                      window.location.reload();
                    }
                  });
                  return throwError(invalid_grant);
                })
              )
            }
          }else{ // other status
            this.storeRequestStatusEvent(false,url);
            this.modal.info({
              nzClassName:'custom-confirm-dialog',
              nzTitle: message + ' <span class="font-bold">and the browser will refresh, continue</span> ?',
              nzOkType: 'primary',
              nzOkText: 'Confirm',
              nzOnOk: () => {
                window.location.reload();
              }
            });
            return throwError(error);
          }
        })
      );
    }else{ //no token
      this.storeRequestStatusEvent(false,url);
      return next.handle(req);
    }
  }

  storeRequestStatusEvent(isLoading: boolean,url: string){
    if(url.indexOf('assets/i18n') === -1){
      setTimeout(()=>{
        this.store.dispatch({
          type: 'progress',
          payload: isLoading ? 'request-loading' : 'request-completed'
        })
      });
    }else{
    }
  }
}