export const LanguageData: any[] = [
    {
        label: 'English',
        value: 0
    },
    {
        label: '繁體中文',
        value: 1
    },
    {
        label: '简体中文',
        value: 2
    }
];