import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { LanguageDialogComponent } from "./language-dialog/language-dialog.component";
import { FormsModule } from "@angular/forms";

@NgModule({
    declarations: [
        LanguageDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NzModalModule,
        NzRadioModule,
    ],
    exports: [
        LanguageDialogComponent
    ]
})

export class ComponentsModule {}