import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NonceService {
  private renderer: Renderer2;
  private nonce: string = '';

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
    const nonceMetaTag = document.querySelector('meta[name="csp-nonce"]');
    if (nonceMetaTag) {
      this.nonce = nonceMetaTag.getAttribute('content') || '';
    } else {
      console.error('Nonce meta tag not found.');
    }
  }

  applyNonceToStyleElement(styleElement: HTMLStyleElement) {
    if (this.nonce) {
      this.renderer.setAttribute(styleElement, 'nonce', this.nonce);
    }
  }

  applyNonceToAllExistingStyles() {
    const styleElements = Array.from(document.querySelectorAll('style'));
    styleElements.forEach(styleElement => {
      this.applyNonceToStyleElement(styleElement as HTMLStyleElement);
    });
  }
}
