<div class="complete-box">
    <div class="title-box">
        <p>HONG KONG QUALITY ASSURANCE AGENCY</p>
        <p>Post Audit Customer Satisfaction Survey</p>
        <p>香港品質保證局</p>
        <p>客戶滿意調查</p>
    </div>
    <div class="complete-content-box">
        <div>
            <p>You have already completed the survey for all the audit(s) that we had delivered, thank you !</p>
            <p>閣下已完成所有相關審核的客戶滿意調查, 謝謝 !</p>
            <p>阁下已完成所有相关审核的客户满意调查, 谢谢 !</p>
        </div>
    </div>
</div>