import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConf } from '../../api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class GetTokenService {
  API_ENV: string = '';

  constructor(
    private http: HttpClient,
    private appConf: AppConf
  ) {
    const { LoginApi } = this.appConf;
    this.API_ENV = LoginApi;
  }

  // get token data
  public PostTokenDataService(data: any): Observable<any>{
    const encodedData = Object.keys(data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');
    const headers = new HttpHeaders()
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Cache-Control', 'no-cache')
    .set('observe', 'body')
    .set('Access-Control-Allow-Origin', '*');
    return this.http.post<any>(
      this.API_ENV,encodedData,{
        headers: headers
      })
  }

  public KeepOnRequestService(request: HttpRequest<any>): HttpRequest<any>{
    const authorization: any = sessionStorage.getItem('Authorization');
    return request.clone({
      headers: request.headers.set('Authorization',(JSON.parse(authorization).token_type) + ' ' + (JSON.parse(authorization).access_token))
    });
  }
}