<div class="layout-box">
    <nz-layout class="layout-box-content">
        <div [class]=" isSuccess ? 'header-bar success-bar' : 'header-bar'">
            <div class="header-content">
                <div class="left-box">
                    <img src="./assets/images/HKQAA_logo.png" alt="">
                </div>
            </div>
            <div class="header-bg">
                <img src="./assets/images/bg.png" alt="">
            </div>
        </div>
        <nz-content id="contentId">
            <router-outlet></router-outlet>
        </nz-content>
        <p class="prompt-information" [hidden]="promptInfo === ''">{{promptInfo}}</p>
    </nz-layout>
    <app-language-dialog
        *ngIf="languageDialog.isVisible"
        [dialogData]="languageDialog"
        (childEvent)="radioCallBackEvent($event)">
    </app-language-dialog>
</div>