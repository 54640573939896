import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { NonceService } from './app/service/nonce/nonce.service';
import * as trustedTypes from 'trusted-types';//特别注意，这里不能删除：虽然置灰，但是这里必须引入的，否则打包会错误。

// 检查 Trusted Types 是否可用并设置默认策略
if (window.trustedTypes && typeof window.trustedTypes.createPolicy === 'function') {
  window.trustedTypes.createPolicy('default', {
    createHTML: (input) => input,
    createScript: (input) => input,
    createScriptURL: (input) => input,
  });
} else {
  console.warn('Trusted Types not supported in this environment.');
}

// 获取 nonce 值
const nonceMetaTag = document.querySelector('meta[name="csp-nonce"]');
if (!nonceMetaTag) {
  console.error('Nonce meta tag not found.');
} else {
  const nonce = nonceMetaTag.getAttribute('content');
  if (!nonce) {
    console.error('Nonce attribute not found.');
    platformBrowserDynamic().bootstrapModule(AppModule);
  } else {
    // 自定义 appendChild 方法以处理新的内联样式
    const originalAppendChild = Node.prototype.appendChild;
    Node.prototype.appendChild = function <T extends Node>(child: T): T {
      if (child instanceof HTMLStyleElement || child instanceof HTMLScriptElement) {
        child.setAttribute('nonce', nonce);
      }
      return originalAppendChild.call(this, child) as T;
    };

    // 在 nonce 获取完成后启动 Angular 应用
    platformBrowserDynamic().bootstrapModule(AppModule).then(moduleRef => {
      const injector = moduleRef.injector;
      const nonceService = injector.get(NonceService);

      // 将 nonce 应用到所有现有内联样式
      nonceService.applyNonceToAllExistingStyles();

      // 监控新的内联样式并设置 nonce
      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.type === 'childList') {
            mutation.addedNodes.forEach(node => {
              if (node.nodeType === Node.ELEMENT_NODE) {
                if ((node as Element).tagName === 'STYLE' || (node as Element).tagName === 'SCRIPT') {
                  (node as HTMLElement).setAttribute('nonce', nonce);
                }
              }
            });
          }
        });
      });

      observer.observe(document.head, { childList: true, subtree: true });
    }).catch(err => console.error(err));
  }
}
