const telRegExp: RegExp = /^(0\d{2,3}\-)?([2-9]\d{6,7})+(\-\d{1,6})?$/;
const phoneRegExp: RegExp = /^(((\+86)|(\+86-))|((86)|(86\-))|((0086)|(0086\-)))?1[3|5|7|8]\d{9}$/;
const anyRegExp: RegExp = /^.{1,200}$/;
const numberRegExp: RegExp = /^[0-9]{8,20}$/;

export {
    telRegExp,
    phoneRegExp,
    anyRegExp,
    numberRegExp
}