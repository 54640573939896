import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { PostSurveyService } from '../../../service/post-survey/post-survey.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Base64 } from 'js-base64';
import { GetTokenService } from '../../../service/get-token/get-token.service';
import { TokenParams } from '../../../config';
import { GetJsonService } from '../../../service/get-json/get-json.service';
import { formatDataDataFn, translationDateDataFn } from '../../../pipe/format-date';
import { LanguageData } from '../../../components/language-dialog/dialog.data';
@Component({
  selector: 'app-post-survey-list',
  templateUrl: './post-survey-list.component.html',
  styleUrls: ['./post-survey-list.component.scss']
})

export class PostSurveyListComponent implements OnInit {
  translationData: any;
  auditListData: any[] = [];
  indeterminate = false;
  checked = false;
  setOfCheckedId = new Set<number>();
  isLayout: boolean = false;
  initResultData: any;
  allDisabled: boolean = true;
  isRequest: boolean = false;
  remarkRequire: boolean = false;
  languageType: number = 0;
  languageDialog: any = {
    isVisible: false,
    languageData: LanguageData,
    activeLanguage: 0
  };
  isGetListData: boolean = false;

  constructor(
    private router: Router,
    private store: Store<any>,
    private message: NzMessageService,
    private activatedRoute: ActivatedRoute,
    private getJsonService: GetJsonService,
    private getTokenService: GetTokenService,
    private postSurveyService: PostSurveyService
  ) { }

  ngOnInit() {
    this.store.subscribe(data => {
      if(Object.keys(data).length > 0 && data.data){
        const { type, payload } = data.data;
        switch(type){
          case 'languageType':
            this.isGetListData = true;
            this.getEmailParamsData(Number(payload));
            break;
          case 'progress':
            this.isRequest = (payload === 'request-loading' ? true : false);
            break;
        }
      }
    });
    const languageNumber = sessionStorage.getItem('languageNumber');
    if(languageNumber && this.isGetListData === false){
      this.getEmailParamsData(Number(languageNumber));
    }else{
      this.languageDialog.isVisible = true;
    }
  }

  // change event
  changeLanguageEvent(languageNum: number) {
    return new Promise(resolve=>{
      this.languageType = languageNum;
      switch(languageNum){
        case 0: // en
          this.getJsonService.getJsonDataService('./assets/i18n/en.json').subscribe(res=>{
            this.translationData = this.recordEvent(this.translationData?.listModel?.additionalAuditNo,res);
            this.isLayout = true;
          });
          resolve('语言项en');
          break;
        case 1: // tc
          this.getJsonService.getJsonDataService('./assets/i18n/tc.json').subscribe(res=>{
            this.translationData = this.recordEvent(this.translationData?.listModel?.additionalAuditNo,res);
            this.isLayout = true;
          });
          resolve('语言项tc');
          break;
        case 2: // sc
          this.getJsonService.getJsonDataService('./assets/i18n/sc.json').subscribe(res=>{
            this.translationData = this.recordEvent(this.translationData?.listModel?.additionalAuditNo,res);
            this.isLayout = true;
          });
          resolve('语言项sc');
          break;
        case 99: // default layout
          this.getJsonService.getJsonDataService('./assets/i18n/en.json').subscribe(res=>{
            this.translationData = this.recordEvent(this.translationData?.listModel?.additionalAuditNo,res);
            this.isLayout = true;
          });
          break;
      }
    });
  }

  // get email params data
  async getEmailParamsData(languageNum: number) {
    const lang: any = await this.changeLanguageEvent(languageNum);
    let AuthorizationData: any = sessionStorage.getItem('Authorization');
    if(!AuthorizationData){
      this.getTokenService.PostTokenDataService(TokenParams).toPromise().then(res=>{
        // set cache message to sessionStorage
        sessionStorage.setItem('Authorization',JSON.stringify(res));
        const {_value:{id,name}} = JSON.parse(JSON.stringify(this.activatedRoute.params));
        this.getAuditSurveyResultData(name + '/' + id);
      }).catch((error: any)=>{
        this.message.create('error',error,{nzDuration: 3000});
      });
    }else{
      const {_value:{id,name}} = JSON.parse(JSON.stringify(this.activatedRoute.params));
      this.getAuditSurveyResultData(name + '/' + id);
    }
  }

  // get audit survey result data
  getAuditSurveyResultData(url: string): void{
    this.postSurveyService.GetAuditSurveyDataService(url).subscribe(res=>{
      const { code,data,result } = res;
      switch(code){
        case '200_10':
          this.initResultData = data;
          this.auditListData = this.initResultData.auditInfos;
          this.allDisabled = ((data && data.auditInfos && data.auditInfos.length > 0) ? false : true);
          this.dataChange();
          break;
        case '300_31':
          this.message.create('success',result,{nzDuration: 3000});
          this.router.navigate(['success']);
          break;
      }
    });
  }

  // input event
  inputEvent(params: any,idx: number): any{
    const { listModel: {additionalAuditNo} } = this.translationData;
    const existAuditData: any[] = [];
    const reg: RegExp = /^\d{0,8}$/;
    additionalAuditNo.forEach((item: any,auditIdx: number)=>{
      switch(item.isRequire){
        case false:
          if(idx !== auditIdx){
            existAuditData.push(item.value);
          }
          break;
      }
    });
    switch(reg.test(params.value)){
      case true:
        if(params.value.length === 0 || params.value.length === 8){
          params.isRequire = false;
        }else{
          params.isRequire = true;
        }
        break;
      case false:
        params.isRequire = true;
        break;
    }
    if(params.value.length === 8){
      switch((Array.from(this.setOfCheckedId).concat(existAuditData)).includes(params.value)){
        case true:
          this.message.create('success','Data has been added');
          setTimeout(()=>{
            params.value = '';
          });
          break;
      }
    }
    let requireNumber: number = 0;
    this.translationData.listModel.additionalAuditNo.forEach((item: any)=>{
      switch(item.isRequire){
        case true:
          requireNumber++;
          break;
      }
    });
    this.remarkRequire = (requireNumber !== 0 ? true : false);
    return params;
  }

  // check all event
  onItemChecked(id: number,checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }

  onAllChecked(checked: boolean): void {
    this.auditListData.filter(({ disabled }) => !disabled).forEach(({ atsAuditNo }) => this.updateCheckedSet(atsAuditNo, checked));
    this.refreshCheckedStatus();
  }

  refreshCheckedStatus(): void {
    const listOfEnabledData = this.auditListData.filter(({ disabled }) => !disabled);
    this.checked = listOfEnabledData.every(({ atsAuditNo }) => this.setOfCheckedId.has(atsAuditNo));
    this.indeterminate = listOfEnabledData.some(({ atsAuditNo }) => this.setOfCheckedId.has(atsAuditNo)) && !this.checked;
    this.resetAdditionAuditNoEvent(Array.from(this.setOfCheckedId));
  }

  updateCheckedSet(id: number,checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }

  // reset additionAuditNo
  resetAdditionAuditNoEvent(params: any[]): void{
    this.translationData.listModel.additionalAuditNo.map((item: any)=>{
      switch(params.includes(item.value)){
        case true:
          item.value = '';
          break;
      }
      return item;
    });
  }

  // record event
  recordEvent(oldData: any,newData: any): any{
    if(oldData){
      // additional audit no additionalAuditNo
      newData.listModel.additionalAuditNo.map((item: any,itemIdx: number)=>{
        item.value = oldData[itemIdx].value;
        item.isRequire = oldData[itemIdx].isRequire;
        return item;
      });
      this.dataChange();
    }
    return newData;
  }

  dataChange(): void{
    switch(this.languageType){
      case 0: // en
        if(this.initResultData){
          this.initResultData.companyName = this.initResultData.companyNameL1;
          this.auditListData.map((item: any)=>{
            if(item.auditTeamInfos){
              item.auditTeamInfos.map((child: any)=>{
                child.auditTeamName = (child.auNameENG ? child.auNameENG : '--');
                return child;
              })
            }
            return item;
          });
        }
        break;
      case 1: // tc
        if(this.initResultData){
          this.initResultData.companyName = this.initResultData.companyNameL2;
          this.auditListData.map((item: any)=>{
            if(item.auditTeamInfos){
              item.auditTeamInfos.map((child: any)=>{
                child.auditTeamName = (child.auNameTCH ? child.auNameTCH : '--');
                return child;
              });
            }
            return item;
          });
        }
        break;
      case 2: // sc
        if(this.initResultData){
          this.initResultData.companyName = this.initResultData.companyNameL2;
          this.auditListData.map((item: any)=>{
            if(item.auditTeamInfos){
              item.auditTeamInfos.map((child: any)=>{
                child.auditTeamName = (child.auNameSCH ? child.auNameSCH : '--');
                return child;
              })
            }
            return item;
          });
        }
        break;
    }
  }

  // button event
  btnEvent(): void{
    const selectData: any[] = [];
    const additionalData: any[] = [];
    const checkedAuditNo: string[] = [];
    const inputAuditNo: string[] = [];
    let passNumber: number = 0;
    let noPassNumber: number = 0;
    const { listModel:{additionalAuditNo},detailModel: {companyLabel} } = this.translationData;
    this.auditListData.map((item: any)=>{
      if(Array.from(this.setOfCheckedId).includes(item.atsAuditNo)){
        selectData.push(item);
      }
    });
    additionalAuditNo.forEach((item: any)=>{
      noPassNumber++;
      if(item.value !==''){
        additionalData.push(item.value);
        inputAuditNo.push(item.value);
        if(item.value.length === 8){
          passNumber++;
        }
      }else{
        passNumber++;
      }
    });
    const initCompany: any[] = [];
    selectData.forEach((item: any,parentIdx: number)=>{
      const childCompany: any[] = [];
      switch(parentIdx){
        case 0:
          companyLabel.forEach((child: any,itemIdx: number)=>{
            switch(itemIdx){
              case 0: // audit no
                childCompany.push({
                  label: child.label,
                  value: item.atsAuditNo
                })
                break;
              case 1: // end date
                childCompany.push({
                  label: child.label,
                  value: translationDateDataFn(formatDataDataFn(item.auditEndDate,'YYYY-MM-DD'))
                })
                break;
              case 2: // audit type
                childCompany.push({
                  label: child.label,
                  value: item.auditType
                })
                break;
              case 3: // company name
                childCompany.push({
                  label: child.label,
                  value: this.initResultData?.companyName,
                  companyNameL1: this.initResultData?.companyNameL1,
                  companyNameL2: this.initResultData?.companyNameL2,
                  companyNameL3: this.initResultData?.companyNameL2
                })
                break;
              case 4: // tel no
                childCompany.push({
                  label: child.label,
                  value: '',
                  isInput: true,
                  emptyL1: child.emptyL1,
                  emptyL2: child.emptyL2,
                  emptyL3: child.emptyL3,
                  validL1: child.validL1,
                  validL2: child.validL2,
                  validL3: child.validL3,
                  error: child.value
                })
                break;
              case 5: // filled by
                childCompany.push({
                  label: child.label,
                  value: '',
                  isInput: true,
                  emptyL1: child.emptyL1,
                  emptyL2: child.emptyL2,
                  emptyL3: child.emptyL3,
                  validL1: child.validL1,
                  validL2: child.validL2,
                  validL3: child.validL3,
                  error: child.value
                })
                break;
            }
          });
          initCompany.push({
            company: childCompany
          });
          break;
        default:
          additionalData.push(item.atsAuditNo);
          break;
      }
      checkedAuditNo.push(item.atsAuditNo);
    });
    const obj: any = {
      additionalAudit: [...new Set(additionalData)],
      company: initCompany,
      parentData: {
        queVer: JSON.parse(JSON.stringify(this.activatedRoute.params))?._value?.name,
        id: JSON.parse(JSON.stringify(this.activatedRoute.params))?._value?.id
      },
      checkedAuditNo: checkedAuditNo,
      inputAuditNo: inputAuditNo
    }
    let urlParams = {
      queryParams: {
        data: Base64.encode(JSON.stringify(obj))
      }
    };
    if(passNumber === noPassNumber){
      this.router.navigate(['list/survey'],urlParams);
    }
  }
}