<div class="post-survey-detail" [hidden]="!isLayout">
    <div class="title-box">
        <div>
            <p>{{translationData?.listModel?.title1}}</p>
            <p>{{translationData?.listModel?.title2}}</p>
        </div>
    </div>
    <div class="company-box">
        <div class="audit-content-box">
            <p [innerHTML]="translationData?.detailModel?.fillInformation"></p>
            <div
                class="overflow-box"
                *ngFor="let item of translationData?.detailModel?.auditListData;let index = index;">
                <div
                    *ngFor="let child of item.company;let index = index;"
                    class="item-box">
                    <p>
                        {{child.label}}
                        <span [hidden]="!child.isInput">*</span>
                        <span class="symbol-span">:</span>
                    </p>
                    <div [class]="child.isRequire ? 'error-input' : ''">
                        <span
                            *ngIf="!child.isInput">
                            {{child.value}}
                        </span>
                        <input
                            placeholder="content"
                            *ngIf="child.isInput"
                            [(ngModel)]="child.value"
                            (ngModelChange)="inputEvent(child,index)"
                            nz-input />
                        <span *ngIf="child.isInput && child.isRequire" class="error">{{child.error}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="questionnaire-box">
            <p class="explain-p" [hidden]="this.detailData.additionalAudit.length === 0 ? true : false">{{translationData?.detailModel?.resultReportNo}} :
                <span
                    *ngFor="let item of this.detailData.additionalAudit;"
                    class="audit-span">
                    {{item}}
                </span>
            </p>
            <div
                [class]="this.detailData.additionalAudit.length === 0 ? 'score-status-box border-class' : 'score-status-box'"
                [hidden]="isRequest">
                <p
                    *ngFor="let item of translationData?.detailModel?.scoringStatus;">
                    {{item}}
                </p>
            </div>
            <div
                *ngFor="let item of translationData?.detailModel?.questionnaire;"
                class="questionnaire-content-box"
                [hidden]="isRequest">
                <p *ngIf="languageNum === 0">{{item.subheadingL1}}</p>
                <p *ngIf="languageNum === 1">{{item.subheadingL2}}</p>
                <p *ngIf="languageNum === 2">{{item.subheadingL3}}</p>
                <div
                    *ngFor="let child of item.question;"
                    [class]="child.type === 2 ? 'question-box question-textarea' : 'question-box'">
                    <p *ngIf="languageNum === 0">{{child.queDescL1}}</p>
                    <p *ngIf="languageNum === 1">{{child.queDescL2}}</p>
                    <p *ngIf="languageNum === 2">{{child.queDescL3}}</p>
                    <div class="score-box">
                        <nz-radio-group
                            *ngIf="child.type === 0"
                            [(ngModel)]="child.value">
                            <label nz-radio nzValue="1">1</label>
                            <label nz-radio nzValue="2">2</label>
                            <label nz-radio nzValue="3">3</label>
                            <label nz-radio nzValue="4">4</label>
                            <label nz-radio nzValue="5">5</label>
                            <label nz-radio nzValue="6">6</label>
                            <label nz-radio nzValue="7">7</label>
                        </nz-radio-group>
                        <nz-radio-group
                            *ngIf="child.type === 1"
                            [(ngModel)]="child.value">
                            <label nz-radio nzValue="1">
                                <span *ngIf="languageNum === 0">{{child.yesL1}}</span>
                                <span *ngIf="languageNum === 1">{{child.yesL2}}</span>
                                <span *ngIf="languageNum === 2">{{child.yesL3}}</span>
                            </label>
                            <label nz-radio nzValue="2">
                                <span *ngIf="languageNum === 0">{{child.noL1}}</span>
                                <span *ngIf="languageNum === 1">{{child.noL2}}</span>
                                <span *ngIf="languageNum === 2">{{child.noL3}}</span>
                            </label>
                        </nz-radio-group>
                        <textarea
                            *ngIf="child.type === 2"
                            nz-input
                            placeholder="content"
                            [(ngModel)]="child.value"
                            class="custom-textarea">
                        </textarea>
                    </div>
                </div>
            </div>
            <div
                class="loading-box"
                [hidden]="!isRequest">
                <p>Loading</p>
                <span nz-icon nzType="loading" nzTheme="outline"></span>
            </div>
        </div>
        <div class="btn-box">
            <button
                (click)="btnEvent()"
                nzType="primary"
                [disabled]="isRequest"
                nz-button>
                {{translationData?.detailModel?.completeButton}}
            </button>
        </div>
    </div>
</div>