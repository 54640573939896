import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConf } from '../../api';
import { ResponseModel } from '../../model';

@Injectable({
  providedIn: 'root'
})

export class PostSurveyService {
  API_ENV: string = '';

  constructor(
    private http: HttpClient,
    private appConf: AppConf
  ) {
    const { PostAuditSurveySummaryAPI:{
      PostAuditModuleName1:{
        AswaAuditSurveyWebAccount
      }
    }} = this.appConf;
    this.API_ENV = AswaAuditSurveyWebAccount;
  }

  // get audit survey data
  public GetAuditSurveyDataService(url: string,data?: any): Observable<ResponseModel<any>>{
    return this.http.get<ResponseModel<any>>(
      this.API_ENV + '/' + url)
  }
}